  $('.first-level-items').slick({
  	arrows: false,
  	dots: false,
  	infinite: true,
  	slidesToShow: 1,
  	slidesToScroll: 1,
  	autoplay: true,
  	autoplaySpeed: 4000,
  	speed: 1000,
  	cssEase: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)'
  });

  
    $('.reviews-container').slick({
  	arrows: false,
  	dots: false,
  	infinite: true,
  	slidesToShow: 2,
  	slidesToScroll: 1,
  	autoplay: true,
  	autoplaySpeed: 4000,
  	speed: 1000,
  	cssEase: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
	 centerMode: true,
  centerPadding: '10 px'
  });
  
    $('.sales-today').slick({
  	arrows: false,
  	infinite: true,
  	slidesToShow: 1,
  	slidesToScroll: 1,
  	autoplay: true,
  	autoplaySpeed: 4000,
  	speed: 1000,
  	cssEase: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)'
  });
  
  