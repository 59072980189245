$('#main-form').submit(function() {
    var errors = false;
    // Проверка на ошибки
    $('input, textarea').each(function() {
        if ($(this).hasClass("error")) {
            errors = true;
        };
    });

    // Если нет ошибок
    if (!errors) {
        var data = $('form').serialize();
        $.ajax({
            url: 'plugins/phpm/mail.php',
            type: 'POST',
            data: data,
            beforeSend: function() {
                // Создаем параграф для вывода состояния
                $('.result').slideDown(400);
                $('.result p').text('Отправляю...');
            },
            success: function(res) {
                if (res == '1') {
                    $('form').find('input:not(#submit), textarea').val('');
                    $('.result').slideDown(400);
                    $('.result img').show();
                    $('.result p').text('Отправлено');
                    $('.result').delay(3000).slideUp(400);
                } else {
                    $('.result').slideDown(400);
                    $('.result p').text('Ошибка!').css('color', 'red');
                    $('.result').slideUp(400);
                }
            },
        });
    };
    return false;
});