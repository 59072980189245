$('.reviews-items').imagesLoaded( function() {
  // init Masonry
  $('.reviews-items').masonry({
    // options...
		itemSelector: '.reviews-item',
		columnWidth: '.reviews-item',
		percentPosition: true
  });
  // Masonry has been initialized, okay to call methods
  // $('.reviews-items').append( $('.reviews-item') )
  //   .masonry( 'appended', $('.reviews-item') );
});
