// Открыть модалку вступления в группу через 10 сек
function showModal() {
    $('.modal-vk-wrapper').css('display','block').delay(10000);
};

setTimeout(showModal, 10000);

// Закрыть модалку вступления в группу ВК
$('.modal-vk-wrapper .modal-close').on('click', function () {
    $('.modal-vk-wrapper').css('display', 'none');
});