$('.burger-container').hover(function() {
	$(".burger-toggle").toggleClass("burger-hover");
});

$(".burger-container").click(function() {
    if ($(".menu-burger-items").css("margin-left") == "-600px") {
        $(".menu-burger-items").animate({ "margin-left": '+=600'});
        $(".menu-burger-items").css("display", "block");
        $(".menu-burger-items").css("opacity", "1");
        $(".menu-burger-items").css("left", "0");
        $(".mask").addClass("mask-opened");
        $(".burger-toggle").addClass("active");
    } else {
        $(".menu-burger-items").animate({ "margin-left": '-=600'});
        $(".menu-burger-items").css("display", "none");
        $(".menu-burger-items").css("opacity", "0");
        $(".mask").removeClass("mask-opened");
        $(".burger-toggle").removeClass("active");
    };
    return;
});

$(document).click(function(event) {
    if ($(event.target).closest(".burger-container").length) return;
    $(".menu-burger-items").animate({ "margin-left": '-600'}, 'fast');
    $(".menu-burger-items").css("display", "none");
    $(".menu-burger-items").css("opacity", "0");
    $(".mask").removeClass("mask-opened");
    $(".burger-toggle").removeClass("active");
    event.stopPropagation();
});
