$(function() {
    $("input[type='checkbox']").change(function() {
        $("#ckbx-style-2-1").toggleClass('error');
    });
});

$(function() {
    $('.btn-trans').on('click',function(){
        if ($("#ckbx-style-2-1").hasClass('error')) {
            $(".check").addClass('red');
        }
    });
});

$(function() {
    $('#ckbx-style-2-1').on('click',function(){
        if ($("#ckbx-style-2-1").hasClass('error')) {
            $(".check").removeClass('red');
        }
    });
});

$("#main-form").validate({
    rules: {
        user_name: {
            required: true,
            minlength: 2,
        },

        user_phone: {
            required: true,
        },

        user_email: {
            email: true,
        },
    },


    messages: {
        user_name: {
            required: "Заполните поле Имя",
            minlength: jQuery.validator.format("Поле Имя должно быть более {0} символов")
        },

        user_email: {
            email: "Пожалуйста укажите верный Email",
        },

        user_phone: {
            required: "Заполните поле Телефон",
        },
    }
});
$('.input-phone').mask("+7(999)9999999", { placeholder: " " });
$('.car-year').mask("9999", { placeholder: " " });
$('.car-engine').mask("9.9", { placeholder: " " });